import React, { Component } from 'react';
import StripeScriptLoader from 'react-stripe-script-loader';
import {
    CardElement,
    injectStripe,
    StripeProvider,
    Elements,
} from 'react-stripe-elements';
import {  FormGroup, Label} from 'reactstrap';
import "../../../config/import";
import apiHandler from "../../../utilities/handlers/apiHandler";
import apiUrls from "../../../constants/apiUrls";
import '../subscription/subscription.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
const countries = require('countrycitystatejson');
const phoneNumber = require( 'awesome-phonenumber' );

const createOptions = () => {
    return {
        style: {
            base: {
                fontSize: '16px',
                color: '#424770',
                fontFamily: 'Open Sans, sans-serif',
                letterSpacing: '0.025em',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            invalid: {
                color: '#c23d4b',
            }

        }
    }
};

class _CardForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: '',
            token: null,
            data: '',
            pub_token: '',
            uid: '',
            element: '',
            name: '',
            email: '',
            phoneNumber: '',
            addrLine1: '',
            addrLine2: '',
            city: '',
            customerState: 'Alabama',
            country: 'US',
            countryList: countries.getCountries(),
            stateList: {},
            cityList: {}
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.isUserDetailsValid = this.isUserDetailsValid.bind(this);
      }


    handleChange = (e) => {
        if (e.error) {
            this.setState({ errorMessage: e.error.message });
        }
        else {
          this.setState({errorMessage:""});
        }
    };

    subscribePlan = function(){
        const user = JSON.parse(localStorage.getItem('User'));
        const authToken = user['authToken'];
        const planData = this.props.productData;
        apiHandler
        .postRequest((apiUrls.baseUrl.development + apiUrls.endPoints.subscriptions), { Authorization: authToken }, planData)
        .then(response => {
            window.location = '/payment';
            })
        .catch(err => {
            console.log("err", err);
        });
    }

    handleSubmit = (evt) => {
        evt.preventDefault();
        this.isUserDetailsValid(evt);
        var form = document.querySelector('form');
        if (form.checkValidity()) {
          let intentObj = JSON.parse(localStorage.getItem('intentObj'));
          localStorage.removeItem('intentObj');
          if (this.props.stripe) {
              this.props.stripe._apiKey = intentObj.pub_token;

              if(intentObj.token != undefined && this.state.element != undefined){
                  this.props.stripe.handleCardSetup(
                      intentObj.token.token, this.state.element,
                      {
                          payment_method_data: {
                            billing_details: {
                              name: this.state.name,
                              email: this.state.email,
                              phone: this.state.phoneNumber,
                              address: {
                                  city: this.state.city,
                                  country: this.state.country,
                                  line1: this.state.addrLine1,
                                  line2: this.state.addrLine2,
                                  state: this.state.customerState
                                }
                            },
                          }
                      }
                 ).then(response => {
                          if (response.error) {
                             console.log(response.error);
                             return false;
                         }
                         else if (response) {
                          const user = JSON.parse(localStorage.getItem('User'));
                          const authToken = user['authToken'];
                          const data = {
                                          uid: intentObj.token.uid,
                                          payment_method: response.setupIntent.payment_method,
                                          created: response.setupIntent.created,
                                          payment_method_type: response.setupIntent.payment_method_types[0],
                                          status: response.setupIntent.status
                                      }
                          apiHandler
                              .postRequest((apiUrls.baseUrl.development + apiUrls.endPoints.setupAttach), { Authorization: authToken }, data)
                              .then(response => {
                                  this.subscribePlan(intentObj.token.uid);
                                  // window.location = '/payment/cards';
                                  })
                              .catch(err => {
                                  console.log("err", err);
                              });

                      }
                     })
                     .catch(err => {
                         console.log("err", err);
                     });
              } else{
                  this.subscribePlan();
              }

          } else {
              console.log("Stripe.js hasn't loaded yet.");
          }
        }
    };

    isInputValid(e) {
      if (e.currentTarget.value.trim() == "") {
        e.currentTarget.classList.add("payment-page-error");
        e.currentTarget.setCustomValidity("Incomplete Form");
      }
      else {
        e.currentTarget.classList.remove("payment-page-error");
        e.currentTarget.setCustomValidity("");
      }
      this.setState({errorMessage: e.currentTarget.validationMessage});
    }

    isUserDetailsValid(e) {
      var inputFields = document.querySelector('form').getElementsByClassName('paymentDetails');
      inputFields = Array.prototype.slice.call(inputFields);
      var errors = [];
      inputFields.forEach(function (k) {
        if (k.value.trim() == "") {
          k.classList.add("payment-page-error");
          k.setCustomValidity("Incomplete Form");
          if (errors.length == 0) {
            errors.push(k.validationMessage);
          }
        }
        else {
          k.classList.remove("payment-page-error");
          k.setCustomValidity("");
        }
      }.bind(this));
      this.setState({errorMessage: errors[errors.length-1]});
    }

    fetchStates = function(countryVal, route=null) {
      let stateList = countries.getStatesByShort(countryVal);
      if (route) {
        this.setState({stateList: stateList, country: countryVal, customerState: countryVal == 'US' ? stateList[3] : stateList[0]});
        document.getElementById("state").selectedIndex = "0";
        this.fetchCities(countryVal, countryVal == 'US' ? stateList[3] : stateList[0],'c');
      }
      else {
        this.setState({stateList: stateList, country: countryVal});
        this.fetchCities(countryVal, this.state.customerState);
      }
    }

    fetchCities = function(countryCode, e, route=null) {
      let stateVal = '';
      if(undefined != e.currentTarget ){
        stateVal = e.currentTarget.value;
      } else {
        stateVal = e;
      }
      if (route) {
        this.setState({cityList: countries.getCities(countryCode, stateVal), city: ""})
        document.getElementById("city").selectedIndex = "0";
      }
      else {
        this.setState({cityList: countries.getCities(countryCode, stateVal)});
      }
    }

    handleReady = (element) => {
        this.setState( {element: element})
      }
    componentDidMount() {
      this.fetchStates('US');
      this.setState({customerState: "Alabama"});
      }

    render() {
        return (
            <div className="CardDemo customeform" style={{ textAlign: 'center'}}>
            <form onSubmit={this.handleSubmit.bind(this)} noValidate>
                Card details
                <CardElement
                  onChange={this.handleChange}
                  {...createOptions()}
                />
                  <FormGroup>
                      <Label for="exampleEmail">Name</Label>
                      <input className="paymentDetails" type="text" id='name' placeholder='Name' onChange={e => {
                          this.isInputValid(e)
                          this.setState({
                              name: e.target.value
                          });
                      }}></input>
                  </FormGroup>

                  <FormGroup>
                      <Label for="address1">Address Line1</Label>
                      <input className="paymentDetails" type="text" id='address1' placeholder='Address Line 1' onChange={e => {
                          this.isInputValid(e)
                          this.setState({
                              addrLine1: e.target.value
                          });
                      }}></input>
                  </FormGroup>

                  <FormGroup>
                      <Label for="address2">Address Line2</Label>
                      <input className="paymentDetails" type="text" id='address2' placeholder='Address Line 2' onChange={e => {
                          this.isInputValid(e)
                          this.setState({
                              addrLine2: e.target.value
                          });
                      }}></input>
                  </FormGroup>

                  <FormGroup>
                      <Label for="country">Country</Label>
                        <select className="input-xlarge controls" id="country"
                          onChange={e => {this.fetchStates(e.currentTarget.value,'s');
                            this.isInputValid(e)
                            this.setState({
                                country: e.target.value
                            });
                          }}>
                          {this.state.countryList.map((country,index) => (
                            country["shortName"] == "US" ? <option value={country["shortName"]} key={index} selected>{country["name"]}</option> : <option value={country["shortName"]} key={index}>{country["name"]}</option>
                          ))}
                        </select>
                  </FormGroup>

                  <FormGroup>
                      <Label for="state">State</Label>
                      <select className="input-xlarge" id="state"
                        onChange={e => {this.fetchCities(this.state.country,e,'s');
                        this.isInputValid(e)
                        this.setState({
                            customerState: e.target.value
                        });
                      }}>
                      {
                          Object.values(this.state.stateList)
                          .map((value,index) =>
                            this.state.customerState == value ? <option  value={value} selected>{value}</option> : <option value={value}>{value}</option>
                          )
                      }</select>
                  </FormGroup>

                  <FormGroup>
                      <Label for="city">City</Label>
                      <select className="input-xlarge" id="city"
                        onChange={e => {
                          this.isInputValid(e)
                          this.setState({
                              city: e.target.value
                          });
                      }}>
                      {
                          Object.values(this.state.cityList)
                          .map(value =>
                            this.state.city == value ? <option value={value} selected>{value}</option> : <option>{value}</option>
                          )
                      }
                      </select>
                  </FormGroup>

                  <FormGroup style={{ textAlign: 'center' }}>

              <div className="error" role="alert">
                {this.state.errorMessage}
              </div>

              <button className="customepaybtn"> Pay</button>
              </FormGroup>
            </form>
          </div>
        );
    }
}

const CardForm = injectStripe(_CardForm);

export class StripeComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
          interObject: null,
          payment: 'false'
        };
    }

    componentWillMount() {
        const user = JSON.parse(localStorage.getItem('User'));
        if(!user){
            this.props.router.push('/login');
            return false;
        }
        const authToken = user['authToken'];
        const setup_intent = this.props.location.state.is_subscribed == true ? '/subscriptions/resubscribe' : '/setup_intent';
        let data = this.props.location.state.is_subscribed == true ? {plan: this.props.location.state.planData} : {}; 
        apiHandler
        .postRequest((apiUrls.baseUrl.development + setup_intent), { Authorization: authToken }, data)
        .then(response => {
          if (response.status == 201) {
              if (response.data.data.setup_intent) {
                let setupIntentObj = {
                  token: response.data.data.setup_intent,
                  pub_token: response.data.data.setup_intent.pub_key,
                  uid: response.data.data.setup_intent.uid
                }
                localStorage.setItem('intentObj', JSON.stringify(setupIntentObj));
                this.setState({
                  interObject: setupIntentObj,
              });
            }
            else {
              this.setState({payment: 'true'});
            }
              
          } else if(response.status == 409 || response.status == 204){
              this.setState({
                payment: 'true'
            });
          }
           else if (response.status == 400 || response.data.message == 'Customer not found') {
            this.props.router.push('/createCustomer');
          }

        })
        .catch(err => {
          console.log("err", err);
        });
      }

    render() {
        return (
        <div>
            {
            this.state.payment === 'true'
            ?
            (<div className="sucessfullwrapper">
            <div className="sucessfuulcontant">
                <span className="rightcheck"><FontAwesomeIcon icon={faCheckCircle} /></span>
                <div className="paysustxt">Payment Successful !</div>
                <p className="patmentdec">Your payment was successful! you can now continue using PatakaPDF.</p>
                <div className="dashboardlink"><span className="dashbtn"><a href='/documents' style={{color: "#fff"}}>Go to Documents</a></span></div>
            </div>
        </div>)
            :
            (
            this.state.interObject !== null
            ?
            (
              <StripeScriptLoader
                uniqueId='stripePataka'
                script='https://js.stripe.com/v3/'
                loader="Loading..."
              >
                <StripeProvider apiKey= { this.state.interObject.pub_token } >
                      <Elements >
                          <CardForm handleResult={this.props.handleResult} productData={this.props.location.state.productData} userEmail={this.props.location.state.email} />
                      </Elements>
                  </StripeProvider>
              </StripeScriptLoader>
            )
            : this.state.interObject
            )
        }
        </div>

        );
    }
}

export default StripeComponent;
